import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { calculateYearsSinceDate } from '../utils/dateUtils'
import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"
import { graphql, useStaticQuery } from 'gatsby';
import { css } from '@emotion/react';
import styled from '@emotion/styled';






const IndexPage = () => {
const targetDate = '2009-01-01'; // Replace with your desired target date
const yearsSinceDate = calculateYearsSinceDate(targetDate);


  return (

  <Layout>
 <div>
<h1>ADAM – Window Cleaning Services.</h1>
 We offer traditional window cleaning and by reach and wash system – whatever my customers prefer.

Our service is for everyone who wishes a single or a regular cleaning, for private customers and for companies. We are reliable and do the job with passion.

We can also offer a gutter cleaning.

For a free quote please call Adam on 07835 071812

We work around Devizes, Chippenham, Calne, Rowde, Melksham, Lacock,
 </div>
  </Layout>
);
  };

  const BackgroundImage = styled.div`
  min-height: 100vh;
  background-image: url(${props => props.backgroundImage});
  background-size: cover;
  background-position: center;
`;

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.2);
`;

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */


export const Head = () => <Seo title="Home" />

export default IndexPage