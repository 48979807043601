import * as React from "react"
import { Link } from "gatsby"
import logo from '../images/logo.webp';


const Header = ({ siteTitle }) => (
  <header
    style={{
      margin: `0 auto`,
      padding: `var(--space-4) var(--size-gutter)`,
      display: `flex`,
      alignItems: `center`,
      justifyContent: `left`,
      maxWidth: `62.5rem`,
      //background: `lightgrey`,
    }}
  >
 
    <Link
      to="/"
      style={{
        fontSize: `var(--font-lg)`,
        textDecoration: `none`,
      }}
    >
       <img
  alt="Adam logo"
  height={100}
  width={77}
  style={{ margin: 0 }}
  src={logo}
/>{siteTitle}
    </Link>
    

  </header>
)

export default Header