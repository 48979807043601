export const calculateYearsSinceDate = (date) => {
    const currentDate = new Date();
    const targetDate = new Date(date);
  
    const yearsDiff = currentDate.getFullYear() - targetDate.getFullYear();
  
    // Adjust the difference if the current month and day are earlier than the target month and day
    if (
      currentDate.getMonth() < targetDate.getMonth() ||
      (currentDate.getMonth() === targetDate.getMonth() &&
        currentDate.getDate() < targetDate.getDate())
    ) {
      return yearsDiff - 1;
    }
  
    return yearsDiff;
  };
  