import * as React from "react"



const Footer = ({ siteTitle }) => (
    <footer
    style={{
      marginTop: `var(--space-5)`,
      fontSize: `var(--font-sm)`,
    }}
  >
    © 2009 - {new Date().getFullYear()} &middot; All rights reserved by <a href="https://adamcleaning.co.uk/">Adam Window Cleaning Services</a>, Powered by MrTommyD
   
    
  </footer>
)

export default Footer
